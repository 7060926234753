<template>
    <div :class="eventPause ? 'workout__video' : 'workout__video explained-pause'">
        <div class="deckslide__header">
            <div class="deckslide__header__left">


                <!-- Moderation on -->
                <img v-show="moderationEnabled" @click="showSoundOptions" src="@/assets/ic_nav_music-on.svg">

                <!-- Music On -->
                <img v-show="!moderationEnabled" @click="showSoundOptions" src="@/assets/ic_nav_music-on-active.svg">

                <!-- Contextmenu: Moderation vs. Music -->
                <div class="deckslide__header__contextmenu">
                    <ul v-if="showSoundMenu">
                        <li>
                            <input type="radio" value="true" v-model="moderationEnabled">
                            <label for="moderation" @click="moderationOn">Moderation</label>
                        </li>
                        <li>
                            <input type="radio" value="false" v-model="moderationEnabled">
                            <label for="music" @click="moderationOff">Eigene Musik / Podcast</label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="deckslide__header__center">
                Workout
            </div>
            <div class="deckslide__header__right">
                <img src="@/assets/ic_nav_close_white.svg" alt="" @click="confirmQuit">
            </div>
        </div>

        <div class="workout__video__wrapper">
            <div v-show="orientation == 'landscape'" class="control-toggle" @click="toggleControlsLandscape"></div>

            <div v-show="eventCountdown || eventPause" class="workout__video__pause">
                <div class="workout__video__pause__countdown">
                    {{ countdownLength }}
                </div>
                <h3>Kurze Pause...</h3>
            </div>

            <div class="workout__video__progress">
                <div class="workout__video__progress__bar" :style="'width: ' + videoProgress + '%'"></div>
            </div>

            <div v-show="!eventPause" class="workout__video__time">
                {{ elapsedTimeDisplay }} <span>| {{ durationDisplay }}</span>
            </div>

            <video-player v-if="videoOptions" @timeUpdate="timeUpdate" @playerEnded="finishWorkout" @sendPlayerRef="getPlayerRef" :options="videoOptions"/>
        </div>

        <div v-show="!hideControlsLandscape && !eventPause" class="video-controls">
            <div class="video-controls__prev">
                <img @click="rewind" src="@/assets/video-control--back15.svg" alt="Previous Video">
            </div>
            <div class="video-controls__pause">
                <img v-show="videoPlaying" @click="togglePlay" src="@/assets/video-control--pause.svg" alt="Pause Video">
                <img v-show="!videoPlaying" @click="togglePlay" src="@/assets/video-control--play.svg" alt="Pause Video">
            </div>
            <div class="video-controls__next">
                <img @click="forward" src="@/assets/video-control--next15.svg" alt="Next Video">
            </div>
        </div>

        
        <v-btn v-show="eventPause" @click="cancelPauseEvent" type="button" class="button button--primary button--small">Pause überspringen</v-btn>

        <ConfirmQuitModal @reallyQuit="reallyQuit" @cancelQuit="cancelQuit"></ConfirmQuitModal>
    </div>
</template>

<script>
import ConfirmQuitModal from '@/components/ConfirmQuitModal.vue';
import 'video.js/dist/video-js.css'

import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
    name: 'WorkoutVideo',
    data() {
        return {
            workoutSource: null,
            videoOptions: null,
            elapsedTime: 0,
            player: null,
            restartPlayer: false,
            videoPlaying: false,
            orientation: null,
            hideControlsLandscape: false,
            hideControlsTimeout: null,
            videoProgress: 0,
            eventCountdown: false,
            eventPause: false,
            countdownLength: 0,
            pauseInterval: null,
            videoEvents: [],
            currentVideoEvent: null,
            fitnessPlan: null,
            moderationEnabled: true,
            showSoundMenu: false,
        }
    },
    methods: {
        showSoundOptions() {
            this.showSoundMenu = !this.showSoundMenu;
        },
        moderationOff() {
            if (this.player) {
                this.moderationEnabled = false;
                this.showSoundOptions()
                this.player.muted(!this.moderationEnabled);
                this.$store.commit('setSoundEnabled', this.moderationEnabled);
            }
        },
        moderationOn() {
            if (this.player) {
                this.moderationEnabled = true;
                this.showSoundOptions()
                this.player.muted(!this.moderationEnabled);
                this.$store.commit('setSoundEnabled', this.moderationEnabled);
            }
        },
        handleOrientationChange() {
            let orientation = screen.orientation.type.match(/\w+/)[0];
            this.orientation = orientation;
            if (orientation == "landscape") {
                this.hideControlsLandscape = true;
            }
            else {
                this.hideControlsLandscape = false;
            }
        },
        handleOrientationChangeFallback() {
            let orientation = window.orientation;
            if (orientation === undefined) { return; }
            if (orientation == 0) {
                this.orientation = "portrait";
                this.hideControlsLandscape = false;
            }
            else {
                this.orientation = "landscape";
                this.hideControlsLandscape = true;
            }
        },
        toggleControlsLandscape() {
            if (this.orientation == "landscape") {
                this.hideControlsLandscape = !this.hideControlsLandscape;
                if (this.hideControlsTimeout) {
                    clearTimeout(this.hideControlsTimeout);
                }
                if (!this.hideControlsLandscape) {
                    this.hideControlsTimeout = setTimeout(this.toggleControlsLandscape, 3000);
                }
            }
        },
        restartHideControlsTimeout() {
            if (this.hideControlsTimeout) {
                clearTimeout(this.hideControlsTimeout);
            }
            this.hideControlsTimeout = setTimeout(this.toggleControlsLandscape, 3000);
        },
        init(){
            try {
                screen.orientation.onchange = this.handleOrientationChange;
                setTimeout(this.handleOrientationChange, 2000);
            }
            catch(error) {
                // not available on iphone
                //alert(error);
                window.addEventListener("orientationchange", this.handleOrientationChangeFallback, false);
                setTimeout(this.handleOrientationChangeFallback, 2000);
            }
            this.moderationEnabled = this.$store.getters.getSoundEnabled;
            this.workoutSource = this.$store.getters.getWorkoutSource;
            if (this.workoutSource.workoutVideo && this.workoutSource.workoutVideo.video_events) {
                this.videoEvents = [...this.workoutSource.workoutVideo.video_events];
                this.videoEvents.forEach(ve => {
                    try {
                        let event_start = ve.event_start
                        let sep = event_start.indexOf(":");
                        if (sep == -1) { return; }
                        let minutes = parseInt(event_start.substring(0, sep));
                        let seconds = parseInt(event_start.substring(sep+1));
                        ve.event_start_seconds = minutes * 60 + seconds;
                    }
                    catch(error) { console.log(error); }
                })
            }
            this.videoOptions = {
                autoplay: true,
                controls: false,
                fluid: true,
                muted: !this.moderationEnabled,
                nativeControlsForTouch: false,
                sources: [
                    {
                        src: "https://media.sportlerplus.com/workouts/" + this.workoutSource.workoutVideo.video_file,
                        type: "video/mp4"
                    }
                ]
            }
        },
        timeUpdate(playerTime) {
            this.videoPlaying = !this.player.paused();
            this.elapsedTime = Math.round(playerTime);
            this.videoProgress = Math.round(this.elapsedTime  * 100 / this.player.duration());
            /* handle events */
            if (this.videoEvents) {
                let new_event = this.videoEvents.find(ve => ve.event_start_seconds == this.elapsedTime);
                if (new_event) {
                    if (new_event.event_type == 1) {
                        this.startCountdownEventWithPause(new_event);
                    }
                    else if (new_event.event_type == 2) {
                        this.startCountdownEvent(new_event);
                    }
                    // remove event from list
                    this.videoEvents = this.videoEvents.filter(ve => ve.id != new_event.id);
                    this.currentVideoEvent = new_event;
                }
            }
        },
        togglePlay() {
            if (this.player.paused()) {
                this.player.play();
                this.videoPlaying = true;
            }
            else {
                this.player.pause();
                this.videoPlaying = false;
            }
        },
        seek(secs) {
            let time = this.player.currentTime() + secs;

            if (time < 0) {
                time = 0;
            }

            this.player.currentTime(time);
        },
        forward() {
            this.seek(15);
            this.cancelCountdownEvent();
            this.restoreEvents();
            this.restartHideControlsTimeout();
        },
        rewind() {
            this.seek(-15);
            this.cancelCountdownEvent();
            this.restoreEvents();
            this.restartHideControlsTimeout();
        },
        restoreEvents() {
            this.videoEvents = [...this.workoutSource.workoutVideo.video_events];
        },
        finishWorkout() {
            if (this.workoutSource) {
                this.addEnergyConsumption(this.elapsedTime, this.workoutSource.workoutVideo.mez);
                //this.workoutSource.completedExercises = [[this.elapsedTime, this.workoutSource.workoutVideo.mez]];
            }
            this.$router.push("/playlist/success");
        },
        reallyQuit() {
            if (window.localStorage.getItem('originWorkoutId')) {
                this.$router.push("/workouts/" + window.localStorage.getItem('originWorkoutId') + "/?back=true");
                window.localStorage.removeItem('originWorkoutId');
            }
            else if (this.workoutSource.fitnessPlanEvent) {
                this.markFitnessPlanEventAsCancelled(this.workoutSource.fitnessPlanEvent);
                this.$router.push("/fitnessplan");
            }
            else {
                this.$router.push("/trainieren");
            }
        },
        async markFitnessPlanEventAsCancelled(eventInfo) {
            await this.$store.dispatch('fetchFitnessPlan').then( (res) => {
				if (res.length != 0) {
					this.fitnessPlan = res[0];
                    let existingStartedActivity = null;
                    if (this.fitnessPlan.startedActivities) {
                        existingStartedActivity = this.fitnessPlan.startedActivities.find(el => el.guid == eventInfo.guid && el.activityDate == eventInfo.activityDate && el.activityTime == eventInfo.activityTime);
                    }
                    else {
                        this.fitnessPlan.startedActivities = [];
                    }

                    if (existingStartedActivity) {
                        existingStartedActivity.activityState = "cancelled";
                        this.$store.dispatch('saveFitnessPlan', this.fitnessPlan);
                    }
                }
            });
        },
        getPlayerRef(ref) {
            this.player = ref;
        },
        confirmQuit() {
            let pauseActive = this.player.paused();
            if (!pauseActive) {
                this.player.pause();
                this.restartPlayer = true;
            }
            else {
                this.restartPlayer = false;
            }
            this.$modal.show('confirmQuit')
        },
        cancelQuit() {
            if (this.restartPlayer) {
                this.restartPlayer = false;
                this.player.play();
            }
        },
        playSoundSample() {
            if (!this.moderationEnabled) { return; }
            let sampleURL = "/sounds/Beep_1.mp3";
            let audioObj = new Audio(sampleURL);
            audioObj.play();
        },
        cancelPauseEvent() {
            this.eventPause = false;
            this.eventCountdown = false;
            this.currentVideoEvent = null;
            if (this.pauseInterval) {
                clearInterval(this.pauseInterval);
                this.pauseInterval = null;
            }
            this.player.play();
        },
        pauseWorkout(callback) {
            this.pauseInterval = setInterval(() => {
                if (this.countdownLength-- == 1) {
                    clearInterval(this.pauseInterval);
                    callback();
                }
                else if (this.countdownLength == 3) {
                    this.playSoundSample();
                }
            }, 1000);
        },
        simpleCountdown(callback) {
            this.pauseInterval = setInterval(() => {
                if (!this.videoPlaying) { return; }
                if (this.countdownLength-- == 1) {
                    clearInterval(this.pauseInterval);
                    callback();
                }
            }, 1000);
        },
        cancelCountdownEvent() {
            this.eventCountdown = false;
            this.currentVideoEvent = null;
            if (this.pauseInterval) {
                clearInterval(this.pauseInterval);
                this.pauseInterval = null;
            }
        },
        startCountdownEventWithPause(videoEvent) {
            this.countdownLength = videoEvent.event_length;
            this.player.pause();
            this.videoPlaying = false;
            this.eventCountdown = true;
            this.eventPause = true;
            this.pauseWorkout(() => {
                this.addEnergyConsumption(parseInt(videoEvent.event_length), 1);
                this.eventCountdown = false;
                this.eventPause = false;
                this.player.play();
                this.videoPlaying = true;
            });
        },
        startCountdownEvent(videoEvent) {
            this.countdownLength = videoEvent.event_length;
            this.eventCountdown = true;
            this.simpleCountdown(() => {
                this.eventCountdown = false;
            });
        },
        /* duration in seconds, factor = mez value from exercise or 1 for pause */
        addEnergyConsumption(duration, factor) {
            if (duration == 0) { return; }
            //console.log("adding consumption: " + duration + "s, " + factor);
            let arr = this.workoutSource.completedExercises || [];
            arr.push([duration, factor]);
        },
    },
    components: { VideoPlayer, ConfirmQuitModal },
    computed: {
        elapsedTimeDisplay() {
            if (!this.player) { return ""; }
            let seconds = this.elapsedTime % 60;
            seconds = ("0" + seconds).slice(-2);
            let minutes = Math.floor(this.elapsedTime / 60);
            return minutes + ":" + seconds;
        },
        durationDisplay() {
            if (!this.player) { return ""; }
            let videoDuration = Math.round(this.player.duration());
            if (videoDuration > 0) {
                let seconds = videoDuration % 60;
                seconds = ("0" + seconds).slice(-2);
                let minutes = Math.floor(videoDuration / 60);
                return minutes + ":" + seconds;
            }
            return "0:00";
        },
    },
    mounted() {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.idleTimerMessageHandler) {
            window.webkit.messageHandlers.idleTimerMessageHandler.postMessage({disabled: true})
        }
        else if (window.AndroidJSInterface != undefined) {
            window.AndroidJSInterface.acquireWakeLock();
        }
        else {
            this.vueInsomnia().on();
        }
        this.init();
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
        if (this.pauseInterval) {
            clearInterval(this.pauseInterval);
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.idleTimerMessageHandler) {
            window.webkit.messageHandlers.idleTimerMessageHandler.postMessage({disabled: false})
        }
        else if (window.AndroidJSInterface != undefined) {
            window.AndroidJSInterface.releaseWakeLock();
        }
        else {
            this.vueInsomnia().off();
        }
    },
}
</script>
<style lang="scss" scoped>
.workout__video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: #000;
    z-index: 1;
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    padding: 0 20px 8px;

    @media screen and (orientation: landscape) {
        justify-content: flex-start;

        .playlist__header {
            position: relative;
            z-index: 3;
        }
    }

    &__close {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0px;
      padding: 12px;

      @media screen and (min-width: 768px) {
        right: -12px;
      }
    }

    &__wrapper {
        position: relative;
        margin: auto -20px;
        width: calc(100% + 40px);

        @media screen and (orientation: landscape) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            overflow: hidden;
        }
    }

    &__pause {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($colorBlack, .8);

        @media screen and (orientation: landscape) {
            padding-bottom: 48px;
            pointer-events: none;
        }

        .explained-pause & {
            background: none;
        }

        &__countdown {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            width: 96px;
            height: 96px;
            padding-bottom: 4px;
            margin: auto 0 4px;
            font-size: 30px;
            letter-spacing: -1.33px;
            font-weight: 900;
            color: $colorWhite;
            background: url('../assets/countdown.png');
            background-size: cover;
            animation: pulse infinite 1s;

            .explained-pause & {
                margin: 0;
                position: absolute;
                top: -48px;

                @media screen and (orientation: landscape) {
                    top: 16px;
                    left: 16px;
                }
            }
        }

        h3 {
            color: $colorWhite;
            margin-bottom: auto;

            .explained-pause & {
                display: none;
            }
        }
    }

    &__sound {
        display: none;

        @media screen and (orientation: landscape) {
            z-index: 3;
            display: flex;
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
    }

    &__progress {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: rgba($colorWhite, .08);

        &__bar {
            height: 2px;
            background: $colorWhite;
        }
    }

    &__time {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -32px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: $colorWhite;

        span {
            opacity: .48;
        }

        @media screen and (orientation: landscape) {
            z-index: 2;
            bottom: 18px;
            left: 24px;
            text-align: left;
            width: auto;
        }
    }

    .button {
        margin: 47px 0;

        @media screen and (orientation: landscape) {
            position: absolute;
            z-index: 3;
            bottom: 24px;
            left: 50%;
            transform: translateX(-50%);
            display: inline-flex;
            width: auto;
            padding-left: 20px !important;
            padding-right: 20px !important;
            margin: 0;
        }
    }
}
</style>